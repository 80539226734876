

  function createAndAnimateStatic() {
    const animationContainer = document.createElement('div');

    // Add the additional styles to animationContainer
    animationContainer.style = `
      flex: none;
      height: 100vh;
      left: 0;
      opacity: .1;
      pointer-events: none;
      position: fixed;
      top: calc(50.00000000000002% - 100vh / 2);
      width: 100%;
      z-index: 1001;
    `;

    // Create the first inner div element
    const animationInnerContainer = document.createElement('div');
    animationInnerContainer.style = 'width:100%;height:100%;position:relative;overflow:hidden';

    // Create the second inner div element
    const static = document.createElement('div');
    static.style = 'background: url("https://loltron.com/static/static.png"); opacity: 0.5; inset: -200%; width: 400%; height: 400%; position: absolute;';

    // Function to animate transformX and transformY properties
    function animateTransform() {
      const randomTransformX = Math.random() * 14 - 7; // Random value between -7 and 7
      const randomTransformY = Math.random() * 14 - 7; // Random value between -7 and 7
      static.style.transform = `translateX(${randomTransformX}%) translateY(${randomTransformY}%) translateZ(0px)`;
    }

    // Call the animation function every 2 seconds (you can adjust the interval)
    setInterval(animateTransform, 100);

    // Append the second inner div element to the first inner div element
    animationInnerContainer.appendChild(static);

    // Append the first inner div element to the main container div element
    animationContainer.appendChild(animationInnerContainer);

    // Append the main container div element to the document body
    document.body.appendChild(animationContainer);

    console.log("%cFrom memes, strength is born, LOLTRON’S light shines anew— Unity in fun.", "font-size: 32px; font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; color: white; text-shadow: -2px 0 4.1px rgba(255, 146, 179, .27), 2px 0 4px rgba(223, 255, 248, .59), 0 0 39.1px hsla(0, 0%, 100%, .29); font-weight: bold; text-transform: uppercase;");

  }

  // Wait for the DOM to fully load before executing the script
  document.addEventListener('DOMContentLoaded', createAndAnimateStatic);

